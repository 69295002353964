// Login.js
import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import localforage from 'localforage';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const auth = useAuth();
  const navigate = useNavigate();
  
//   const onLoad = async () => {
//     try {
//         const storedUser = await localforage.getItem('user');
//         if (storedUser !== null) {
//             auth.login({ username: 'admin' });
//         }
//     } catch (error) {
//         console.error("Failed to load user:", error);
//     }
// }
//   useEffect(onLoad, [])

  

useEffect(() => {
  async function onLoad() {
    const abc = await localforage.getItem('user');
    if (abc !== null) {
      auth.login({ username: 'Imbatman' }); // Ensure this matches your data structure
      navigate('/');
    }
  }
  
  onLoad();
}, []);

  


  const handleSubmit = (event) => {
    event.preventDefault();
    if (username === 'Imbatman' && password === 'Imbatman') {
      localforage.setItem('user','xAD234')
      auth.login({ username });
      navigate('/');
    } else {
      setError('Invalid username or password');
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f0f2f5' }}>
      <form onSubmit={handleSubmit} style={{ padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', backgroundColor: '#fff', width: '300px' }}>
        <h2 style={{ textAlign: 'center', color: '#333' }}>Login</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="username" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Username:</label>
          <input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <label htmlFor="password" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Password:</label>
          <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} />
        </div>
        <button type="submit" style={{ width: '100%', padding: '10px', borderRadius: '4px', border: 'none', backgroundColor: '#007bff', color: '#fff', fontWeight: 'bold', cursor: 'pointer' }}>
          Login
        </button>
      </form>
    </div>
  );
}

export default Login;

// AppRoutes.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';  // Your main App component
import Login from './Login';
import { AuthProvider } from './AuthContext';
import RequireAuth from './RequireAuth';

function AppRoutes() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<RequireAuth><App /></RequireAuth>} />
          {/* Add more protected routes as needed */}
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default AppRoutes;
